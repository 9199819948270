
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="26" height="49" viewBox="0 0 26 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26 44.5L18.5 40.1699V48.8301L26 44.5ZM0.25 0V1.625H1.75V0H0.25ZM0.25 4.875V8.125H1.75V4.875H0.25ZM0.25 11.375V14.625H1.75V11.375H0.25ZM0.25 17.875V21.125H1.75V17.875H0.25ZM0.25 24.375V27.625H1.75V24.375H0.25ZM0.25 30.875V32.5H1.75V30.875H0.25ZM0.25 32.5C0.25 33.0637 0.286631 33.6193 0.357728 34.1643L1.84513 33.9703C1.7824 33.4894 1.75 32.9987 1.75 32.5H0.25ZM1.21777 37.3807C1.64778 38.4177 2.21049 39.3853 2.88452 40.2624L4.0739 39.3484C3.47882 38.574 2.98246 37.7204 2.60336 36.8062L1.21777 37.3807ZM5.2376 42.6155C6.11471 43.2895 7.08231 43.8522 8.11926 44.2822L8.69384 42.8966C7.77965 42.5175 6.92596 42.0212 6.15159 41.4261L5.2376 42.6155ZM11.3357 45.1423C11.8807 45.2134 12.4363 45.25 13 45.25V43.75C12.5013 43.75 12.0106 43.7176 11.5297 43.6549L11.3357 45.1423ZM13 45.25H14.625V43.75H13V45.25ZM17.875 45.25H21.125V43.75H17.875V45.25Z" fill="url(#paint0_linear_16292_192418)"/>
<defs>
<linearGradient id="paint0_linear_16292_192418" x1="2.04167" y1="22.25" x2="26" y2="22.25" gradientUnits="userSpaceOnUse">
<stop stop-color="#5B1FD3"/>
<stop offset="1" stop-color="#44179D"/>
</linearGradient>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'ClaimTodoPendingDashedArrow',
    inheritAttrs: true,
  }
  </script>
